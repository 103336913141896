import demoJSON from "./json/demo/data.json";
// let data = jsonToTemplate(homeJSON);
const Demo = [
  {
    component: "Layout",
    data: {
      active: "Home",
    },
    children: [...demoJSON],
  },
];
export default Demo;
