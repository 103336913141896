import React from "react";
import PropTypes from "prop-types";

const index = (props) => {
  return <div></div>;
};

index.propTypes = {};

export default index;
