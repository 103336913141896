export const MAP = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        name: "Whalley",
        created_at: "2013-03-21T04:05:51.121Z",
        updated_at: "2013-03-21T04:07:11.643Z",
        cartodb_id: 1,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [49.206198, -122.852847],
              [49.206081, -122.83897],
              [49.205918, -122.838533],
              [49.202513, -122.834307],
              [49.202113, -122.834075],
              [49.176987, -122.834421],
              [49.177014, -122.839983],
              [49.173574, -122.839997],
              [49.173569, -122.841025],
              [49.173551, -122.84562],
              [49.173217, -122.845621],
              [49.173242, -122.851469],
              [49.176977, -122.851382],
              [49.17715, -122.851289],
              [49.177186, -122.856704],
              [49.203522, -122.856378],
              [49.203926, -122.856007],
              [49.204704, -122.85489],
              [49.205171, -122.854427],
              [49.205897, -122.853315],
              [49.206198, -122.852847],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "Whalley",
        created_at: "2013-03-21T04:05:51.121Z",
        updated_at: "2013-03-21T04:07:11.643Z",
        cartodb_id: 2,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [49.166842, -122.890132],
              [49.170767, -122.8901],
              [49.1772, -122.889952],
              [49.177202, -122.915434],
              [49.177245, -122.920257],
              [49.182947, -122.917485],
              [49.1872, -122.914025],
              [49.191724, -122.910149],
              [49.196066, -122.905305],
              [49.200136, -122.900599],
              [49.20303, -122.896724],
              [49.206648, -122.89257],
              [49.210084, -122.887726],
              [49.212796, -122.882883],
              [49.215053, -122.876658],
              [49.215683, -122.872925],
              [49.216132, -122.869468],
              [49.216666, -122.861726],
              [49.217021, -122.855643],
              [49.217274, -122.841683],
              [49.217453, -122.839747],
              [49.217894, -122.832005],
              [49.218337, -122.825507],
              [49.218152, -122.822467],
              [49.218146, -122.818873],
              [49.21426, -122.819069],
              [49.213067, -122.817934],
              [49.211862, -122.818338],
              [49.211365, -122.81876],
              [49.211078, -122.818981],
              [49.210502, -122.819503],
              [49.209821, -122.819645],
              [49.209114, -122.819668],
              [49.208917, -122.819589],
              [49.208577, -122.81951],
              [49.208289, -122.819611],
              [49.207398, -122.819474],
              [49.20652, -122.819457],
              [49.205722, -122.81984],
              [49.20457, -122.820324],
              [49.204008, -122.820766],
              [49.203407, -122.821908],
              [49.203015, -122.822509],
              [49.202452, -122.822771],
              [49.20147, -122.823034],
              [49.192066, -122.823076],
              [49.186657, -122.823103],
              [49.184077, -122.823096],
              [49.184164, -122.812065],
              [49.177116, -122.812285],
              [49.176843, -122.812285],
              [49.170116, -122.812291],
              [49.170145, -122.823389],
              [49.156972, -122.82337],
              [49.155527, -122.823359],
              [49.150616, -122.823452],
              [49.150128, -122.823471],
              [49.150132, -122.823489],
              [49.152531, -122.831197],
              [49.154674, -122.839725],
              [49.156326, -122.845634],
              [49.159514, -122.856877],
              [49.162628, -122.856905],
              [49.162591, -122.862449],
              [49.160764, -122.862454],
              [49.160737, -122.867531],
              [49.160735, -122.867773],
              [49.160727, -122.867997],
              [49.162571, -122.868032],
              [49.163023, -122.875362],
              [49.163383, -122.87536],
              [49.163311, -122.873534],
              [49.163365, -122.873534],
              [49.164668, -122.873599],
              [49.164729, -122.875809],
              [49.165647, -122.875834],
              [49.165647, -122.875954],
              [49.166064, -122.875949],
              [49.166076, -122.876379],
              [49.166529, -122.876359],
              [49.166804, -122.879016],
              [49.166842, -122.890132],
            ],
            [
              [49.206198, -122.852847],
              [49.205897, -122.853315],
              [49.205171, -122.854427],
              [49.204704, -122.85489],
              [49.203926, -122.856007],
              [49.203522, -122.856378],
              [49.177186, -122.856704],
              [49.17715, -122.851289],
              [49.176977, -122.851382],
              [49.173242, -122.851469],
              [49.173217, -122.845621],
              [49.173551, -122.84562],
              [49.173569, -122.841025],
              [49.173574, -122.839997],
              [49.177014, -122.839983],
              [49.176987, -122.834421],
              [49.202113, -122.834075],
              [49.202513, -122.834307],
              [49.205918, -122.838533],
              [49.206081, -122.83897],
              [49.206198, -122.852847],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "Cloverdale",
        created_at: "2013-03-21T04:05:51.121Z",
        updated_at: "2013-03-21T04:07:11.643Z",
        cartodb_id: 5,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [49.133561, -122.779098],
              [49.135696, -122.779066],
              [49.138538, -122.779074],
              [49.139449, -122.779009],
              [49.139561, -122.779016],
              [49.140918, -122.778992],
              [49.140899, -122.769401],
              [49.140898, -122.769256],
              [49.140899, -122.76496],
              [49.140905, -122.760577],
              [49.140861, -122.752498],
              [49.140785, -122.746029],
              [49.140786, -122.746029],
              [49.140784, -122.745884],
              [49.144521, -122.745907],
              [49.147757, -122.745919],
              [49.150371, -122.745933],
              [49.155274, -122.745951],
              [49.155275, -122.745951],
              [49.155424, -122.679289],
              [49.140822, -122.679361],
              [49.133625, -122.679522],
              [49.126311, -122.679692],
              [49.126311, -122.679843],
              [49.118401, -122.679975],
              [49.118287, -122.679966],
              [49.118124, -122.679909],
              [49.118015, -122.679842],
              [49.113941, -122.679878],
              [49.111237, -122.679904],
              [49.108144, -122.679917],
              [49.104406, -122.679948],
              [49.104405, -122.680043],
              [49.097635, -122.679795],
              [49.093194, -122.679679],
              [49.089592, -122.679611],
              [49.089573, -122.680888],
              [49.089547, -122.690891],
              [49.08956, -122.704953],
              [49.089562, -122.735136],
              [49.089586, -122.75122],
              [49.089601, -122.769525],
              [49.089656, -122.778262],
              [49.089655, -122.778966],
              [49.089655, -122.779011],
              [49.089655, -122.779111],
              [49.089656, -122.779111],
              [49.089754, -122.779122],
              [49.104368, -122.779165],
              [49.118954, -122.779132],
              [49.133561, -122.779098],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "Guildford",
        created_at: "2013-03-21T04:05:51.121Z",
        updated_at: "2013-03-21T04:07:11.643Z",
        cartodb_id: 6,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [49.217735, -122.815288],
              [49.216151, -122.807835],
              [49.214348, -122.793601],
              [49.213794, -122.787384],
              [49.212691, -122.77882],
              [49.211828, -122.772639],
              [49.21222, -122.769425],
              [49.213113, -122.763201],
              [49.213728, -122.754215],
              [49.213266, -122.749794],
              [49.212617, -122.742887],
              [49.210792, -122.736539],
              [49.20961, -122.734611],
              [49.208701, -122.732819],
              [49.207158, -122.731169],
              [49.204255, -122.728698],
              [49.200812, -122.727749],
              [49.196554, -122.726944],
              [49.194471, -122.726679],
              [49.189488, -122.725601],
              [49.187311, -122.723817],
              [49.185676, -122.721755],
              [49.182981, -122.714962],
              [49.182299, -122.710724],
              [49.182015, -122.705477],
              [49.183155, -122.691658],
              [49.184946, -122.681054],
              [49.185615, -122.679468],
              [49.185302, -122.679481],
              [49.177042, -122.679477],
              [49.166679, -122.679191],
              [49.162881, -122.679101],
              [49.155424, -122.679289],
              [49.155275, -122.745951],
              [49.159568, -122.745883],
              [49.160291, -122.745831],
              [49.160419, -122.756623],
              [49.162866, -122.756623],
              [49.162843, -122.763354],
              [49.166458, -122.763356],
              [49.166392, -122.767739],
              [49.170147, -122.767753],
              [49.176943, -122.767702],
              [49.176901, -122.77893],
              [49.176909, -122.789937],
              [49.17687, -122.797589],
              [49.176843, -122.812285],
              [49.177116, -122.812285],
              [49.184164, -122.812065],
              [49.184077, -122.823096],
              [49.186657, -122.823103],
              [49.192066, -122.823076],
              [49.20147, -122.823034],
              [49.202452, -122.822771],
              [49.203015, -122.822509],
              [49.203407, -122.821908],
              [49.204008, -122.820766],
              [49.20457, -122.820324],
              [49.205722, -122.81984],
              [49.20652, -122.819457],
              [49.207398, -122.819474],
              [49.208289, -122.819611],
              [49.208577, -122.81951],
              [49.208917, -122.819589],
              [49.209114, -122.819668],
              [49.209821, -122.819645],
              [49.210502, -122.819503],
              [49.211078, -122.818981],
              [49.211365, -122.81876],
              [49.211862, -122.818338],
              [49.213067, -122.817934],
              [49.21426, -122.819069],
              [49.218126, -122.818874],
              [49.217735, -122.815288],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "South Surrey",
        created_at: "2013-03-21T04:05:51.121Z",
        updated_at: "2013-03-21T04:07:11.643Z",
        cartodb_id: 3,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [49.081921, -122.850936],
              [49.082551, -122.850653],
              [49.082553, -122.850531],
              [49.083103, -122.850167],
              [49.083641, -122.849801],
              [49.084039, -122.849454],
              [49.084155, -122.849312],
              [49.084319, -122.849101],
              [49.084478, -122.848887],
              [49.084577, -122.848644],
              [49.084756, -122.84831],
              [49.084915, -122.847884],
              [49.084935, -122.847489],
              [49.084974, -122.846942],
              [49.085033, -122.846547],
              [49.085133, -122.846182],
              [49.085252, -122.845878],
              [49.085331, -122.845483],
              [49.085391, -122.845119],
              [49.08541, -122.844693],
              [49.085469, -122.844268],
              [49.085588, -122.84366],
              [49.085707, -122.843295],
              [49.085807, -122.84287],
              [49.086065, -122.841958],
              [49.086204, -122.841623],
              [49.086363, -122.841319],
              [49.086582, -122.840893],
              [49.086821, -122.840346],
              [49.087079, -122.839677],
              [49.087298, -122.83916],
              [49.087417, -122.838613],
              [49.087515, -122.837853],
              [49.087354, -122.836912],
              [49.087294, -122.836305],
              [49.087253, -122.835819],
              [49.087253, -122.835424],
              [49.087312, -122.83509],
              [49.087411, -122.834452],
              [49.08747, -122.833965],
              [49.087728, -122.833084],
              [49.087886, -122.832172],
              [49.088422, -122.829862],
              [49.08868, -122.828889],
              [49.089037, -122.828068],
              [49.089636, -122.827944],
              [49.090038, -122.828028],
              [49.090693, -122.828214],
              [49.091312, -122.828424],
              [49.091871, -122.828409],
              [49.092417, -122.827647],
              [49.092572, -122.826995],
              [49.092486, -122.826355],
              [49.092351, -122.82593],
              [49.092166, -122.825627],
              [49.091902, -122.825339],
              [49.091168, -122.825296],
              [49.090729, -122.82545],
              [49.090432, -122.825598],
              [49.090189, -122.82564],
              [49.089672, -122.825696],
              [49.088896, -122.825497],
              [49.088713, -122.82494],
              [49.088765, -122.824366],
              [49.088789, -122.823757],
              [49.088711, -122.823331],
              [49.088474, -122.823235],
              [49.088013, -122.823424],
              [49.087147, -122.823535],
              [49.086694, -122.823044],
              [49.086575, -122.822427],
              [49.086715, -122.820378],
              [49.08681, -122.819328],
              [49.086769, -122.818599],
              [49.086409, -122.818115],
              [49.085926, -122.818123],
              [49.085311, -122.818028],
              [49.084552, -122.818129],
              [49.083656, -122.817335],
              [49.083567, -122.816273],
              [49.084031, -122.815785],
              [49.084671, -122.815486],
              [49.085455, -122.815005],
              [49.086095, -122.814405],
              [49.087227, -122.813339],
              [49.088176, -122.812884],
              [49.089013, -122.812699],
              [49.08981, -122.812118],
              [49.089811, -122.811219],
              [49.089827, -122.801423],
              [49.089656, -122.779111],
              [49.089655, -122.779111],
              [49.089655, -122.779011],
              [49.089655, -122.778966],
              [49.089655, -122.778966],
              [49.089656, -122.778262],
              [49.089601, -122.769525],
              [49.089586, -122.75122],
              [49.089562, -122.735136],
              [49.08956, -122.704953],
              [49.089547, -122.690891],
              [49.089573, -122.680888],
              [49.089592, -122.67962],
              [49.085957, -122.679805],
              [49.082499, -122.680093],
              [49.076805, -122.680395],
              [49.074639, -122.680569],
              [49.047874, -122.680571],
              [49.038433, -122.680349],
              [49.031103, -122.680263],
              [49.023136, -122.679995],
              [49.01709, -122.679822],
              [49.002198, -122.680117],
              [49.002198, -122.701556],
              [49.002136, -122.731165],
              [49.00212, -122.758022],
              [49.003266, -122.759262],
              [49.004432, -122.76085],
              [49.006535, -122.764056],
              [49.007805, -122.766509],
              [49.010987, -122.773102],
              [49.014403, -122.784831],
              [49.016637, -122.793046],
              [49.016611, -122.779309],
              [49.03104, -122.779274],
              [49.031118, -122.801189],
              [49.031182, -122.829278],
              [49.031204, -122.83151],
              [49.03119, -122.834438],
              [49.031221, -122.843626],
              [49.031224, -122.845395],
              [49.024236, -122.845463],
              [49.024266, -122.851416],
              [49.024393, -122.857496],
              [49.024396, -122.85974],
              [49.024076, -122.861985],
              [49.02408, -122.865427],
              [49.024716, -122.867904],
              [49.025443, -122.869967],
              [49.026147, -122.871606],
              [49.027892, -122.874092],
              [49.029524, -122.875465],
              [49.032422, -122.876146],
              [49.035775, -122.878892],
              [49.038494, -122.88164],
              [49.042118, -122.883973],
              [49.044202, -122.88507],
              [49.046194, -122.885066],
              [49.048095, -122.884373],
              [49.049182, -122.884646],
              [49.051303, -122.885265],
              [49.053344, -122.886362],
              [49.056611, -122.888486],
              [49.057969, -122.88807],
              [49.058239, -122.886416],
              [49.058691, -122.885726],
              [49.060318, -122.883242],
              [49.060497, -122.881588],
              [49.06081, -122.880241],
              [49.061786, -122.879946],
              [49.062939, -122.878826],
              [49.063208, -122.876759],
              [49.061484, -122.869143],
              [49.061569, -122.867807],
              [49.062291, -122.865738],
              [49.062741, -122.86367],
              [49.063192, -122.861877],
              [49.063825, -122.861187],
              [49.065001, -122.860495],
              [49.066359, -122.860215],
              [49.067445, -122.860212],
              [49.070252, -122.859515],
              [49.071791, -122.859511],
              [49.074055, -122.859504],
              [49.075775, -122.859499],
              [49.076772, -122.860461],
              [49.077407, -122.861562],
              [49.079037, -122.861833],
              [49.079668, -122.859488],
              [49.079903, -122.857144],
              [49.079958, -122.856286],
              [49.079392, -122.854229],
              [49.080386, -122.853697],
              [49.081921, -122.850936],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "Fleetwood",
        created_at: "2013-03-21T04:05:51.121Z",
        updated_at: "2013-03-21T04:07:11.643Z",
        cartodb_id: 4,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [49.150128, -122.823471],
              [49.150616, -122.823452],
              [49.155527, -122.823359],
              [49.156972, -122.82337],
              [49.170145, -122.823389],
              [49.170116, -122.812291],
              [49.176843, -122.812285],
              [49.17687, -122.797589],
              [49.176909, -122.789937],
              [49.176901, -122.77893],
              [49.176943, -122.767702],
              [49.170147, -122.767753],
              [49.166392, -122.767739],
              [49.166458, -122.763356],
              [49.162843, -122.763354],
              [49.162866, -122.756623],
              [49.160419, -122.756623],
              [49.160291, -122.745831],
              [49.159568, -122.745883],
              [49.155275, -122.745951],
              [49.155274, -122.745951],
              [49.150371, -122.745933],
              [49.147757, -122.745919],
              [49.144521, -122.745907],
              [49.140784, -122.745884],
              [49.140786, -122.746029],
              [49.140861, -122.752498],
              [49.140905, -122.760577],
              [49.140899, -122.76496],
              [49.140898, -122.769256],
              [49.140899, -122.769401],
              [49.140918, -122.778992],
              [49.140918, -122.779007],
              [49.140879, -122.79676],
              [49.139615, -122.796747],
              [49.13966, -122.801043],
              [49.146567, -122.801157],
              [49.148301, -122.801164],
              [49.148253, -122.817503],
              [49.150128, -122.823471],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "Newton",
        created_at: "2013-03-21T04:05:51.121Z",
        updated_at: "2013-03-21T04:07:11.643Z",
        cartodb_id: 7,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [49.166842, -122.890132],
              [49.166804, -122.879016],
              [49.166529, -122.876359],
              [49.166076, -122.876379],
              [49.166064, -122.875949],
              [49.165647, -122.875954],
              [49.165647, -122.875834],
              [49.164729, -122.875809],
              [49.164668, -122.873599],
              [49.163365, -122.873534],
              [49.163311, -122.873534],
              [49.163383, -122.87536],
              [49.163023, -122.875362],
              [49.162571, -122.868032],
              [49.160727, -122.867997],
              [49.160735, -122.867773],
              [49.160737, -122.867531],
              [49.160764, -122.862454],
              [49.162591, -122.862449],
              [49.162628, -122.856905],
              [49.159514, -122.856877],
              [49.156326, -122.845634],
              [49.154674, -122.839725],
              [49.152531, -122.831197],
              [49.150132, -122.823489],
              [49.150128, -122.823471],
              [49.148253, -122.817503],
              [49.148301, -122.801164],
              [49.146567, -122.801157],
              [49.13966, -122.801043],
              [49.139615, -122.796747],
              [49.140879, -122.79676],
              [49.140918, -122.779007],
              [49.140918, -122.778992],
              [49.139561, -122.779016],
              [49.139449, -122.779009],
              [49.138538, -122.779074],
              [49.135696, -122.779066],
              [49.133561, -122.779098],
              [49.118954, -122.779132],
              [49.104368, -122.779165],
              [49.089754, -122.779122],
              [49.089656, -122.779111],
              [49.089827, -122.801423],
              [49.089811, -122.811219],
              [49.08981, -122.812118],
              [49.089013, -122.812699],
              [49.088176, -122.812884],
              [49.087227, -122.813339],
              [49.086095, -122.814405],
              [49.085455, -122.815005],
              [49.084671, -122.815486],
              [49.084031, -122.815785],
              [49.083567, -122.816273],
              [49.083656, -122.817335],
              [49.084552, -122.818129],
              [49.085311, -122.818028],
              [49.085926, -122.818123],
              [49.086409, -122.818115],
              [49.086769, -122.818599],
              [49.08681, -122.819328],
              [49.086715, -122.820378],
              [49.086575, -122.822427],
              [49.086694, -122.823044],
              [49.087147, -122.823535],
              [49.088013, -122.823424],
              [49.088474, -122.823235],
              [49.088711, -122.823331],
              [49.088789, -122.823757],
              [49.088765, -122.824366],
              [49.088713, -122.82494],
              [49.088896, -122.825497],
              [49.089672, -122.825696],
              [49.090189, -122.82564],
              [49.090432, -122.825598],
              [49.090729, -122.82545],
              [49.091168, -122.825296],
              [49.091902, -122.825339],
              [49.092166, -122.825627],
              [49.092351, -122.82593],
              [49.092486, -122.826355],
              [49.092572, -122.826995],
              [49.092417, -122.827647],
              [49.091871, -122.828409],
              [49.091312, -122.828424],
              [49.090693, -122.828214],
              [49.090038, -122.828028],
              [49.089636, -122.827944],
              [49.089037, -122.828068],
              [49.08868, -122.828889],
              [49.088422, -122.829862],
              [49.087886, -122.832172],
              [49.087728, -122.833084],
              [49.08747, -122.833965],
              [49.087411, -122.834452],
              [49.087312, -122.83509],
              [49.087253, -122.835424],
              [49.087253, -122.835819],
              [49.087294, -122.836305],
              [49.087354, -122.836912],
              [49.087515, -122.837853],
              [49.087417, -122.838613],
              [49.087298, -122.83916],
              [49.087079, -122.839677],
              [49.086821, -122.840346],
              [49.086582, -122.840893],
              [49.086363, -122.841319],
              [49.086204, -122.841623],
              [49.086065, -122.841958],
              [49.085807, -122.84287],
              [49.085707, -122.843295],
              [49.085588, -122.84366],
              [49.085469, -122.844268],
              [49.08541, -122.844693],
              [49.085391, -122.845119],
              [49.085331, -122.845483],
              [49.085252, -122.845878],
              [49.085133, -122.846182],
              [49.085033, -122.846547],
              [49.084974, -122.846942],
              [49.084935, -122.847489],
              [49.084915, -122.847884],
              [49.084756, -122.84831],
              [49.084577, -122.848644],
              [49.084478, -122.848887],
              [49.084319, -122.849101],
              [49.084155, -122.849312],
              [49.084039, -122.849454],
              [49.083641, -122.849801],
              [49.083103, -122.850167],
              [49.082553, -122.850531],
              [49.082526, -122.852277],
              [49.082831, -122.853107],
              [49.083378, -122.853891],
              [49.083591, -122.854122],
              [49.083895, -122.85449],
              [49.084136, -122.854766],
              [49.084255, -122.855093],
              [49.084406, -122.855338],
              [49.084589, -122.855715],
              [49.084807, -122.855921],
              [49.08488, -122.856074],
              [49.084989, -122.85668],
              [49.085032, -122.857106],
              [49.08514, -122.857548],
              [49.085184, -122.857941],
              [49.085266, -122.858738],
              [49.085502, -122.859468],
              [49.08567, -122.859774],
              [49.085995, -122.859965],
              [49.086271, -122.860244],
              [49.08637, -122.86046],
              [49.086406, -122.860803],
              [49.086544, -122.861377],
              [49.086635, -122.861876],
              [49.087091, -122.862429],
              [49.087638, -122.862428],
              [49.087699, -122.86289],
              [49.087487, -122.86326],
              [49.087184, -122.8634],
              [49.086577, -122.863725],
              [49.086487, -122.864649],
              [49.087094, -122.86474],
              [49.087398, -122.86497],
              [49.087398, -122.865109],
              [49.086944, -122.865711],
              [49.087309, -122.866357],
              [49.087855, -122.866032],
              [49.088401, -122.866123],
              [49.088551, -122.866434],
              [49.088766, -122.866584],
              [49.089069, -122.866583],
              [49.089161, -122.866952],
              [49.089373, -122.867044],
              [49.089434, -122.867322],
              [49.089222, -122.867738],
              [49.088919, -122.868201],
              [49.089163, -122.868801],
              [49.089467, -122.869262],
              [49.089831, -122.869261],
              [49.089997, -122.869462],
              [49.090039, -122.869842],
              [49.089998, -122.87033],
              [49.089912, -122.870723],
              [49.089688, -122.871202],
              [49.089388, -122.871975],
              [49.089269, -122.872577],
              [49.089323, -122.872904],
              [49.08942, -122.873002],
              [49.089544, -122.87292],
              [49.089721, -122.872821],
              [49.089838, -122.872943],
              [49.089893, -122.873132],
              [49.089771, -122.873377],
              [49.089743, -122.873558],
              [49.089829, -122.873624],
              [49.089882, -122.873767],
              [49.089862, -122.874033],
              [49.089927, -122.874148],
              [49.090066, -122.87414],
              [49.090175, -122.87436],
              [49.090271, -122.874426],
              [49.090415, -122.874343],
              [49.090702, -122.874654],
              [49.090756, -122.87467],
              [49.091455, -122.876011],
              [49.091506, -122.877174],
              [49.091629, -122.877399],
              [49.091753, -122.877549],
              [49.091699, -122.877645],
              [49.091877, -122.878112],
              [49.091869, -122.879223],
              [49.091656, -122.87954],
              [49.091681, -122.880065],
              [49.09183, -122.880215],
              [49.09183, -122.880553],
              [49.091781, -122.880816],
              [49.091806, -122.881229],
              [49.09188, -122.881642],
              [49.092053, -122.882092],
              [49.091881, -122.882355],
              [49.091857, -122.883107],
              [49.091907, -122.883895],
              [49.091983, -122.885172],
              [49.092083, -122.886523],
              [49.092281, -122.887912],
              [49.09243, -122.888888],
              [49.092456, -122.890352],
              [49.09828, -122.890391],
              [49.098275, -122.890253],
              [49.104637, -122.890278],
              [49.108141, -122.890262],
              [49.110041, -122.89025],
              [49.112917, -122.890259],
              [49.115556, -122.890231],
              [49.119211, -122.890207],
              [49.122956, -122.890199],
              [49.12841, -122.890199],
              [49.133762, -122.890188],
              [49.134348, -122.890167],
              [49.137279, -122.890145],
              [49.140119, -122.890169],
              [49.148334, -122.890205],
              [49.149244, -122.89021],
              [49.150606, -122.890199],
              [49.154211, -122.890192],
              [49.162798, -122.890186],
              [49.166842, -122.890132],
            ],
          ],
          [
            [
              [49.089655, -122.779011],
              [49.089655, -122.778966],
              [49.089655, -122.778966],
              [49.089655, -122.779011],
            ],
          ],
        ],
      },
    },
  ],
};
